@charset 'UTF-8';
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
[data-scrolly] {
  position: relative;
}
[data-scrolly] [data-scrolly-axis] {
  opacity: 0;
}
[data-scrolly]:hover [data-scrolly-axis] {
  opacity: 1;
}
[data-scrolly].scrolly-disabled [data-scrolly-axis] {
  display: none;
}
[data-scrolly].scrolling [data-scrolly-axis] {
  opacity: 1;
}
[data-scrolly].scrolling [data-scrolly-axis]:before {
  background: rgba(0, 0, 0, 0.6);
}
[data-scrolly-viewport] {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
}
[data-scrolly-axis] {
  position: absolute;
  border: 7px solid transparent;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: opacity 0.1s ease;
  -o-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
[data-scrolly-axis]:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: " ";
  background: rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  -webkit-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
[data-scrolly-axis]:hover:before {
  background: rgba(0, 0, 0, 0.6);
}
[data-scrolly-axis=y] {
  top: 0;
  right: 0;
  width: 21px;
  height: 100%;
}
[data-scrolly-axis=x] {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 21px;
}
/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.icon__arrow {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -171px -62px;
  width: 44px;
  height: 32px;
}
.icon__arrow_up {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -219px -35px;
  width: 19px;
  height: 33px;
}
.icon__close--hover {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -157px -169px;
  width: 23px;
  height: 23px;
}
.icon__close {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -219px -69px;
  width: 19px;
  height: 19px;
}
.icon__delivery--l {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -80px 0px;
  width: 90px;
  height: 55px;
}
.icon__delivery {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -80px -112px;
  width: 62px;
  height: 38px;
}
.icon__download {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -181px -169px;
  width: 21px;
  height: 21px;
}
.icon__email {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -136px -89px;
  width: 26px;
  height: 18px;
}
.icon__fb {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -219px 0px;
  width: 20px;
  height: 34px;
}
.icon__in {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -136px -56px;
  width: 31px;
  height: 32px;
}
.icon__location--mini {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -219px -106px;
  width: 13px;
  height: 18px;
}
.icon__mini_arrow {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -219px -89px;
  width: 15px;
  height: 16px;
}
.icon__person--l {
  background-image: url(/assets/static/img/sprite.png);
  background-position: 0px 0px;
  width: 79px;
  height: 79px;
}
.icon__person {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -80px -56px;
  width: 55px;
  height: 55px;
}
.icon__phone {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -143px -112px;
  width: 27px;
  height: 27px;
}
.icon__plus {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -131px -169px;
  width: 25px;
  height: 25px;
}
.icon__slider_arrow--left {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -52px -169px;
  width: 51px;
  height: 30px;
}
.icon__slider_arrow--right {
  background-image: url(/assets/static/img/sprite.png);
  background-position: 0px -169px;
  width: 51px;
  height: 30px;
}
.icon__solution--l {
  background-image: url(/assets/static/img/sprite.png);
  background-position: 0px -80px;
  width: 68px;
  height: 88px;
}
.icon__solution {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -171px 0px;
  width: 47px;
  height: 61px;
}
.icon__tick {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -104px -169px;
  width: 26px;
  height: 25px;
}
.icon__vk {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -171px -95px;
  width: 45px;
  height: 28px;
}
.icon__yb {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -171px -124px;
  width: 38px;
  height: 28px;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: inherit;
  color: inherit;
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1em;
}
body {
  background: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.3em;
  color: #414141;
}
ul {
  list-style-type: none;
}
ol {
  padding-left: 1em;
}
b {
  font-weight: 700;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
table td,
table th {
  padding: 0;
}
select {
  width: 100%;
}
optgroup {
  font-weight: bold;
}
button {
  outline: none;
}
.transition_color {
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.transition_opacity {
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.gradient {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.gradient_reverse {
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
}
a {
  text-decoration: none;
  color: #414141;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
a.link:hover {
  text-decoration: underline;
}
a.link.link--reverse {
  text-decoration: underline;
}
a.link.link--reverse:hover {
  text-decoration: none;
}
a.bordered {
  padding: 2px 5px;
  border: solid 1px transparent;
  border-radius: 2px;
}
a.bordered:hover {
  border-color: #414141;
}
a:active,
a:visited,
a:focus {
  color: #414141;
  outline: none;
}
a:hover {
  color: #414141;
}
.h__tmp {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
}
h1,
.h1 {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
  font-size: 2.75em;
}
h1 small,
.h1 small {
  font-size: 1.25em;
  font-weight: normal;
  text-transform: none;
}
h2,
.h2 {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
  font-size: 1.75em;
}
h3,
.h3 {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
  font-size: 1.25em;
}
h4,
.h4 {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
  font-size: 1.25em;
}
.link {
  color: #414141;
}
.fa {
  margin-right: 0.5em;
}
.green,
.ok,
.fa-check {
  color: green;
  font-weight: 700;
}
.yellow,
.wait {
  color: #bb7d30;
  font-weight: 700;
}
.red,
.error,
.fa-ban,
.fa-trash-o {
  color: red;
  font-weight: 700;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  margin-left: -1em;
  background-image: url(/assets/static/img/loading.gif);
  background-size: cover;
}
.clear_into {
  display: block;
  clear: both;
  overflow: hidden;
}
.clear:after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.text-center {
  text-align: center;
}
.align-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.layout {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 3%;
}
.layout.layout--more {
  padding: 0 6%;
}
.table {
  display: table;
  border-collapse: collapse;
}
.table tbody tr td {
  border: solid 1px #d1d1d1;
}
.table .row {
  display: table-row;
}
.table .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.select__wrapper {
  width: 100%;
}
.select__wrapper .select2-container {
  width: 100% !important;
}
.select2-container.select2 {
  z-index: 1;
}
.select2-container.select2-container--default .select2-selection--multiple,
.select2-container.select2-container--default .select2-selection--single {
  position: relative;
  top: 0px;
  min-height: 47px;
  background-color: transparent;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__rendered,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  color: gray;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow {
  top: 5px;
  right: 10px;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b {
  display: inline-block;
  padding: 0 0.75em;
  line-height: 1.875em;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #1b5e3c;
  border: solid 1px #1b5e3c;
  color: white;
  cursor: pointer;
  border-radius: 0.9375em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 0;
  margin: 0;
  padding: 0px;
  background: transparent;
  border: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b:hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b:hover {
  color: white;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b:active,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b:active,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b:visited,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b:visited,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b:focus,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b:focus {
  color: white;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--border,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--border {
  background: transparent;
  border: solid 1px #414141;
  color: #414141;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--border.active,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--border.active {
  border-color: black;
  color: white;
  background-color: black;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--noradius,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--noradius {
  border-radius: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient {
  position: relative;
  padding: 0;
  border: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient span,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient span {
  position: relative;
  padding: 0 1.25em;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient:active,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient:visited,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient:visited,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient:focus,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient:hover .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient:hover .button__inner,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient.active .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient.active .button__inner {
  opacity: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient:hover .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient:hover .button__inner--hover,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--gradient.active .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--gradient.active .button__inner--hover {
  opacity: 1;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download {
  position: relative;
  padding: 0;
  border: 0;
  line-height: 2.5em;
  border-radius: 0;
  border-bottom: solid 1px #11312a;
  font-weight: 300;
  font-size: 0.875em;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download span,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download span {
  position: relative;
  padding: 0 1.25em;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download:active,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download:visited,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download:visited,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download:focus,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download:hover .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download:hover .button__inner,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download.active .button__inner,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download.active .button__inner {
  opacity: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download:hover .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download:hover .button__inner--hover,
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--download.active .button__inner--hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--download.active .button__inner--hover {
  opacity: 1;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--100,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--100 {
  width: 100%;
  text-align: center;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--color,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--color {
  background: green;
  border-color: green;
  color: white;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--color:hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--color:hover {
  border-color: green;
  background: green;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--white,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--white {
  background: white;
  border-color: white;
  color: #414141;
  line-height: 2.188em;
  /*
        &:hover {
            border-color: @color_active;
            background: @color_active;
            color: white;
        }
        */
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--white.active,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--white.active {
  border-color: black;
  background: black;
  color: white;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--arrow,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--arrow {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--arrow::after,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--more,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--more {
  padding: 0 25px;
  border-radius: 0;
  background: white;
  color: gray;
  border: 0;
  line-height: 3em;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b.button--more:hover,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b.button--more:hover {
  background: black;
  color: white;
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b::after,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.select2-container.select2-container--default .select2-selection--multiple.select2-selection--single .select2-selection__arrow b::after,
.select2-container.select2-container--default .select2-selection--single.select2-selection--single .select2-selection__arrow b::after {
  border-left-color: #1b5e3c;
  border-bottom-color: #1b5e3c;
}
.select2-container.select2-container--default .select2-dropdown {
  position: relative;
  top: -1px;
  border: solid 1px #d1d1d1;
}
/* Icon
<span class="icon icon__wrapper icon__close">
    <span class="icon icon__close"></span>
    <span class="icon icon__close--black"></span>
</span>

.icon.icon__wrapper.icon__close.icon__wrapper--hover-on
    .icon.icon__close
    .icon.icon__close--black
*/
.icon {
  display: block;
  position: relative;
  float: left;
  margin-right: 0.5em;
}
.icon.icon--right {
  float: right;
  margin-right: 0;
  margin-left: 0.5em;
}
.icon__wrapper {
  display: block;
  position: relative;
  background: transparent;
}
.icon__wrapper.icon__wrapper--hover-on {
  cursor: pointer;
}
.icon__wrapper.icon__wrapper--hover-on:hover .icon:first-child {
  opacity: 0;
}
.icon__wrapper.icon__wrapper--hover-on:hover .icon:last-child {
  opacity: 1;
}
.icon__wrapper .icon {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.icon__wrapper .icon:last-child {
  opacity: 0;
}
.icon__title:hover .icon__wrapper--hover-on {
  cursor: pointer;
}
.icon__title:hover .icon__wrapper--hover-on .icon:first-child {
  opacity: 0;
}
.icon__title:hover .icon__wrapper--hover-on .icon:last-child {
  opacity: 1;
}
/* Arrow
*/
.arrow {
  display: block;
  position: relative;
  width: 0px;
  height: 0px;
}
.arrow::after {
  content: '';
  display: inline-block;
  position: relative;
  margin-left: 7px;
  width: 0px;
  height: 0px;
}
.arrow::before {
  content: '';
  display: inline-block;
  position: relative;
  margin-right: 0px;
  width: 0px;
  height: 0px;
}
.arrow.arrow--left::after {
  display: none;
}
.arrow.arrow--left::before {
  top: 0px;
  border-style: solid;
  border-width: 5px 8px 5px 8px;
  border-color: transparent #1b5e3c transparent transparent;
}
.arrow.arrow--left--right::after {
  top: 0px;
  right: 6px;
  border-style: solid;
  border-width: 4.5px 6px 4.5px 6px;
  border-color: transparent #1b5e3c transparent transparent;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.arrow.arrow--left--right::before {
  display: none;
}
.arrow.arrow--right::after {
  display: none;
}
.arrow.arrow--right::before {
  top: 0px;
  border-style: solid;
  border-width: 5px 8px 5px 8px;
  border-color: transparent transparent transparent #1b5e3c;
}
.arrow.arrow--down::after {
  display: none;
}
.arrow.arrow--down::before {
  top: 5px;
  margin-right: 7px;
  border-style: solid;
  border-width: 8px 5px 8px 5px;
  border-color: #1b5e3c transparent transparent transparent;
}
.arrow.arrow--down--right::after {
  top: 5px;
  border-style: solid;
  border-width: 6px 4.5px 6px 4.5px;
  border-color: #1b5e3c transparent transparent transparent;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.arrow.arrow--down--right::before {
  display: none;
}
.arrow.arrow--up::after {
  top: -2px;
  border-style: solid;
  border-width: 6px 4.5px 6px 4.5px;
  border-color: transparent transparent #1b5e3c transparent;
}
.arrow.arrow--up::before {
  display: none;
}
/* Button
    .button
    ---
    .button.button--gradient
        .button__inner
        .button__inner--hover
        span Отправить
    
*/
.button {
  display: inline-block;
  padding: 0 0.75em;
  line-height: 1.875em;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #1b5e3c;
  border: solid 1px #1b5e3c;
  color: white;
  cursor: pointer;
  border-radius: 0.9375em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.button:hover {
  color: white;
}
.button:active,
.button:visited,
.button:focus {
  color: white;
}
.button.button--border {
  background: transparent;
  border: solid 1px #414141;
  color: #414141;
}
.button.button--border.active {
  border-color: black;
  color: white;
  background-color: black;
}
.button.button--noradius {
  border-radius: 0;
}
.button.button--gradient {
  position: relative;
  padding: 0;
  border: 0;
}
.button.button--gradient .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.button.button--gradient .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.button.button--gradient span {
  position: relative;
  padding: 0 1.25em;
}
.button.button--gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.button.button--gradient:visited,
.button.button--gradient:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.button.button--gradient:hover .button__inner,
.button.button--gradient.active .button__inner {
  opacity: 0;
}
.button.button--gradient:hover .button__inner--hover,
.button.button--gradient.active .button__inner--hover {
  opacity: 1;
}
.button.button--download {
  position: relative;
  padding: 0;
  border: 0;
  line-height: 2.5em;
  border-radius: 0;
  border-bottom: solid 1px #11312a;
  font-weight: 300;
  font-size: 0.875em;
}
.button.button--download .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.button.button--download .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.button.button--download span {
  position: relative;
  padding: 0 1.25em;
}
.button.button--download:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.button.button--download:visited,
.button.button--download:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.button.button--download:hover .button__inner,
.button.button--download.active .button__inner {
  opacity: 0;
}
.button.button--download:hover .button__inner--hover,
.button.button--download.active .button__inner--hover {
  opacity: 1;
}
.button.button--100 {
  width: 100%;
  text-align: center;
}
.button.button--color {
  background: green;
  border-color: green;
  color: white;
}
.button.button--color:hover {
  border-color: green;
  background: green;
}
.button.button--white {
  background: white;
  border-color: white;
  color: #414141;
  line-height: 2.188em;
  /*
        &:hover {
            border-color: @color_active;
            background: @color_active;
            color: white;
        }
        */
}
.button.button--white.active {
  border-color: black;
  background: black;
  color: white;
}
.button.button--arrow {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.button.button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.button.button--more {
  padding: 0 25px;
  border-radius: 0;
  background: white;
  color: gray;
  border: 0;
  line-height: 3em;
}
.button.button--more:hover {
  background: black;
  color: white;
}
/* Breadcrumbs
*/
.breadcrumbs {
  position: relative;
  margin: 10px 0 25px;
  padding: 10px 0;
}
.breadcrumbs:after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.breadcrumbs li {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
  display: inline-block;
  margin: 0 0 0 20px;
  width: auto;
}
.breadcrumbs li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.breadcrumbs li::after {
  content: "";
  position: absolute;
  top: 4px;
  left: -18px;
  width: 5px;
  height: 5px;
  color: #414141;
  border-left-color: #2d856c;
  border-bottom-color: #2d856c;
  -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
          transform: rotate(225deg);
}
.breadcrumbs li:first-child {
  margin-left: 0;
}
.breadcrumbs li:first-child::after {
  display: none;
}
.breadcrumbs li:last-child {
  color: #c4c4c4;
}
.breadcrumbs li span {
  color: gray;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.breadcrumbs li a span {
  color: #414141;
}
.breadcrumbs li a:hover span {
  color: #414141;
}
/* MoreLink
*/
.more-link {
  display: block;
  position: relative;
  margin: 2em 0;
  padding-left: 1.25em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.more-link.more-link--right {
  float: right;
  padding-right: 15px;
}
.more-link.more-link--right::after {
  top: -1px;
  left: initial;
  right: 0;
}
.more-link.more-link__plus {
  padding-left: 1em;
}
.more-link.more-link__plus::after {
  content: "+";
}
.more-link.more-link__plus.show::after {
  content: "";
}
.more-link::after {
  content: ">";
  position: absolute;
  top: 0;
  left: 0;
}
/* Radio, Checkbox 
/*  <label class="radiocheck">
	    <input type="radiocheck">
	    <span>переключатель</span>
    </label> 
    label.radiocheck
        input(type="checkbox")
        span
            | переключатель
*/
.radiocheck {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* radio */
  /* checkbox */
}
.radiocheck.radiocheck--slide input[type="checkbox"] + span {
  display: block;
  position: relative;
  width: 60px;
  height: 1.5em;
  border: solid 1px gray;
  border-radius: 1em;
  background-color: gray;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
}
.radiocheck.radiocheck--slide input[type="checkbox"] + span::before {
  content: "Нет";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 42.85714286px;
  height: 1.375em;
  border: solid 1px gray;
  border-radius: 1em;
  text-align: center;
  background: #fff;
  line-height: 1.25em;
  color: gray;
  font-weight: normal;
  -webkit-transition: left 0.3s ease 0s;
  -o-transition: left 0.3s ease 0s;
  transition: left 0.3s ease 0s;
}
.radiocheck.radiocheck--slide input[type="checkbox"]:checked + span::before {
  content: "Да";
  left: 15.14285714px;
  background: #1b5e3c;
  color: white;
  -webkit-box-shadow: 0 0 0 0 #1b5e3c;
          box-shadow: 0 0 0 0 #1b5e3c;
}
.radiocheck input[type="radio"] {
  display: none;
}
.radiocheck input[type="radio"] + span::before {
  width: 0.75em;
  height: 0.75em;
  background: white;
  border: solid 1px gray;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
.radiocheck input[type="radio"]:checked + span::before {
  background: #414141;
  -webkit-box-shadow: inset 0 0 0 3px white;
          box-shadow: inset 0 0 0 3px white;
}
.radiocheck input[type="checkbox"] {
  display: none;
}
.radiocheck input[type="checkbox"] + span::before {
  width: 0.75em -3em;
  height: 0.75em -3em;
  background: white;
  border: solid 1px gray;
  outline: none;
  cursor: pointer;
}
.radiocheck input[type="checkbox"]:checked + span::before {
  background: #414141;
  -webkit-box-shadow: inset 0 0 0 1px white;
          box-shadow: inset 0 0 0 1px white;
}
.radiocheck span {
  display: inline-block;
  position: relative;
}
.radiocheck span::after {
  content: "";
  display: block;
  float: left;
  margin-right: 0.625em;
}
.radiocheck span::before {
  content: "";
  display: block;
  float: left;
  margin-right: 0.625em;
}
/* Logo
    a.logo(href="/")
        img.logo__img(src="" alt="")
        .logo__title Логотип
        .logo__subtitle Подлоготип
*/
.logo .logo__img {
  display: inline-block;
}
.logo .logo__img.logo__img--mini {
  display: none;
}
.logo .logo__title {
  display: inline-block;
}
.logo .logo__subtitle {
  display: block;
  width: 100%;
}
/* Sandwich
.sandwich 
    .icon.icon__sandwich
    .icon.icon__sandwich--close
*/
.sandwich__wrapper .sandwich {
  display: none;
  position: relative;
  width: 52px;
  height: 35px;
}
.sandwich__wrapper .sandwich .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
.sandwich__wrapper .sandwich .icon:first-child {
  width: 52px;
  height: 35px;
}
.sandwich__wrapper .sandwich .icon:last-child {
  top: 17.5px;
  left: 26px;
}
.sandwich__wrapper.open .sandwich .icon:first-child {
  width: 0;
  height: 0;
  top: 17.5px;
  left: 26px;
}
.sandwich__wrapper.open .sandwich .icon:last-child {
  top: 4.5px;
  left: 13px;
  width: 26px;
  height: 26px;
}
/* Nav
    .nav
        .sandwich

*/
.nav {
  display: block;
}
.nav .sandwich {
  display: none;
}
.nav .menu.menu--center_item ul > li {
  width: 8.4375em;
  height: 4em;
  text-align: center;
}
.nav .menu.menu--center_item ul > li.active a {
  font-weight: bold;
}
.nav .menu.menu--center_item ul > li.active a::before {
  content: "|";
  position: absolute;
  left: -10px;
  top: -1.13em;
  font-size: 1.3em;
  content: "";
}
.nav .menu.menu--center_item ul > li:hover > ul {
  left: 30px;
}
.nav .menu.menu--center_item ul > li > ul {
  width: 20em;
}
.nav .menu.menu--center_item ul > li > a {
  line-height: 4em;
}
.nav .menu > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.nav .menu > ul > li {
  position: relative;
  margin-right: 50px;
  font-size: 1.25em;
}
.nav .menu > ul > li.active > a {
  position: relative;
}
.nav .menu > ul > li.active > a::before {
  content: "";
  position: absolute;
  top: 1.13em;
  font-size: 1.3em;
  width: 35px;
  height: 3px;
  background: black;
}
.nav .menu > ul > li:hover > ul {
  display: block;
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  background: white;
}
.nav .menu > ul > li:hover > ul li {
  width: 100%;
  height: auto;
  font-size: 0.875em;
  text-align: left;
}
.nav .menu > ul > li:hover > ul li a {
  display: inline-block;
  width: 100%;
  padding: 5px 7px;
  line-height: 1.3em;
  text-transform: none;
  white-space: nowrap;
}
.nav .menu > ul > li:hover > ul li a:hover {
  background: white;
  font-weight: bold;
  color: #414141;
}
.nav .menu > ul > li > ul {
  display: none;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
/* Slider
*/
.slider__wrapper {
  position: relative;
}
.slider__wrapper .slider__control {
  position: relative;
}
.slider__wrapper .slider__control .arrows {
  display: inline-block;
  padding: 0 0.75em;
  line-height: 1.875em;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #1b5e3c;
  border: solid 1px #1b5e3c;
  color: white;
  cursor: pointer;
  border-radius: 0.9375em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  border: 0;
  position: absolute;
  top: 0;
  width: auto;
  height: auto;
  margin: 0;
  background: transparent;
  font-size: 0.75em;
  line-height: 1.25em;
  color: #414141;
  z-index: 1;
}
.slider__wrapper .slider__control .arrows:hover {
  color: white;
}
.slider__wrapper .slider__control .arrows:active,
.slider__wrapper .slider__control .arrows:visited,
.slider__wrapper .slider__control .arrows:focus {
  color: white;
}
.slider__wrapper .slider__control .arrows.button--border {
  background: transparent;
  border: solid 1px #414141;
  color: #414141;
}
.slider__wrapper .slider__control .arrows.button--border.active {
  border-color: black;
  color: white;
  background-color: black;
}
.slider__wrapper .slider__control .arrows.button--noradius {
  border-radius: 0;
}
.slider__wrapper .slider__control .arrows.button--gradient {
  position: relative;
  padding: 0;
  border: 0;
}
.slider__wrapper .slider__control .arrows.button--gradient .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .arrows.button--gradient .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .arrows.button--gradient span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .arrows.button--gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .arrows.button--gradient:visited,
.slider__wrapper .slider__control .arrows.button--gradient:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .arrows.button--gradient:hover .button__inner,
.slider__wrapper .slider__control .arrows.button--gradient.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .arrows.button--gradient:hover .button__inner--hover,
.slider__wrapper .slider__control .arrows.button--gradient.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .arrows.button--download {
  position: relative;
  padding: 0;
  border: 0;
  line-height: 2.5em;
  border-radius: 0;
  border-bottom: solid 1px #11312a;
  font-weight: 300;
  font-size: 0.875em;
}
.slider__wrapper .slider__control .arrows.button--download .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .arrows.button--download .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .arrows.button--download span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .arrows.button--download:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .arrows.button--download:visited,
.slider__wrapper .slider__control .arrows.button--download:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .arrows.button--download:hover .button__inner,
.slider__wrapper .slider__control .arrows.button--download.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .arrows.button--download:hover .button__inner--hover,
.slider__wrapper .slider__control .arrows.button--download.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .arrows.button--100 {
  width: 100%;
  text-align: center;
}
.slider__wrapper .slider__control .arrows.button--color {
  background: green;
  border-color: green;
  color: white;
}
.slider__wrapper .slider__control .arrows.button--color:hover {
  border-color: green;
  background: green;
}
.slider__wrapper .slider__control .arrows.button--white {
  background: white;
  border-color: white;
  color: #414141;
  line-height: 2.188em;
  /*
        &:hover {
            border-color: @color_active;
            background: @color_active;
            color: white;
        }
        */
}
.slider__wrapper .slider__control .arrows.button--white.active {
  border-color: black;
  background: black;
  color: white;
}
.slider__wrapper .slider__control .arrows.button--arrow {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.slider__wrapper .slider__control .arrows.button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .arrows.button--more {
  padding: 0 25px;
  border-radius: 0;
  background: white;
  color: gray;
  border: 0;
  line-height: 3em;
}
.slider__wrapper .slider__control .arrows.button--more:hover {
  background: black;
  color: white;
}
.slider__wrapper .slider__control .arrows::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .arrows:active,
.slider__wrapper .slider__control .arrows:focus,
.slider__wrapper .slider__control .arrows:visited {
  color: #414141;
}
.slider__wrapper .slider__control .arrows::after {
  top: 4px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-left-color: gray;
  border-bottom-color: gray;
}
.slider__wrapper .slider__control .slider__arrow--left {
  display: inline-block;
  padding: 0 0.75em;
  line-height: 1.875em;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #1b5e3c;
  border: solid 1px #1b5e3c;
  color: white;
  cursor: pointer;
  border-radius: 0.9375em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  border: 0;
  position: absolute;
  top: 0;
  width: auto;
  height: auto;
  margin: 0;
  background: transparent;
  font-size: 0.75em;
  line-height: 1.25em;
  color: #414141;
  z-index: 1;
  left: 12px;
  padding-left: 20px;
}
.slider__wrapper .slider__control .slider__arrow--left:hover {
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--left:active,
.slider__wrapper .slider__control .slider__arrow--left:visited,
.slider__wrapper .slider__control .slider__arrow--left:focus {
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--left.button--border {
  background: transparent;
  border: solid 1px #414141;
  color: #414141;
}
.slider__wrapper .slider__control .slider__arrow--left.button--border.active {
  border-color: black;
  color: white;
  background-color: black;
}
.slider__wrapper .slider__control .slider__arrow--left.button--noradius {
  border-radius: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient {
  position: relative;
  padding: 0;
  border: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient:visited,
.slider__wrapper .slider__control .slider__arrow--left.button--gradient:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient:hover .button__inner,
.slider__wrapper .slider__control .slider__arrow--left.button--gradient.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--gradient:hover .button__inner--hover,
.slider__wrapper .slider__control .slider__arrow--left.button--gradient.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download {
  position: relative;
  padding: 0;
  border: 0;
  line-height: 2.5em;
  border-radius: 0;
  border-bottom: solid 1px #11312a;
  font-weight: 300;
  font-size: 0.875em;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--left.button--download:visited,
.slider__wrapper .slider__control .slider__arrow--left.button--download:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--left.button--download:hover .button__inner,
.slider__wrapper .slider__control .slider__arrow--left.button--download.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--download:hover .button__inner--hover,
.slider__wrapper .slider__control .slider__arrow--left.button--download.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .slider__arrow--left.button--100 {
  width: 100%;
  text-align: center;
}
.slider__wrapper .slider__control .slider__arrow--left.button--color {
  background: green;
  border-color: green;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--left.button--color:hover {
  border-color: green;
  background: green;
}
.slider__wrapper .slider__control .slider__arrow--left.button--white {
  background: white;
  border-color: white;
  color: #414141;
  line-height: 2.188em;
  /*
        &:hover {
            border-color: @color_active;
            background: @color_active;
            color: white;
        }
        */
}
.slider__wrapper .slider__control .slider__arrow--left.button--white.active {
  border-color: black;
  background: black;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--left.button--arrow {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.slider__wrapper .slider__control .slider__arrow--left.button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .slider__arrow--left.button--more {
  padding: 0 25px;
  border-radius: 0;
  background: white;
  color: gray;
  border: 0;
  line-height: 3em;
}
.slider__wrapper .slider__control .slider__arrow--left.button--more:hover {
  background: black;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--left::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .slider__arrow--left:active,
.slider__wrapper .slider__control .slider__arrow--left:focus,
.slider__wrapper .slider__control .slider__arrow--left:visited {
  color: #414141;
}
.slider__wrapper .slider__control .slider__arrow--left::after {
  top: 4px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-left-color: gray;
  border-bottom-color: gray;
}
.slider__wrapper .slider__control .slider__arrow--left::after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.slider__wrapper .slider__control .slider__arrow--right {
  display: inline-block;
  padding: 0 0.75em;
  line-height: 1.875em;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #1b5e3c;
  border: solid 1px #1b5e3c;
  color: white;
  cursor: pointer;
  border-radius: 0.9375em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  border: 0;
  position: absolute;
  top: 0;
  width: auto;
  height: auto;
  margin: 0;
  background: transparent;
  font-size: 0.75em;
  line-height: 1.25em;
  color: #414141;
  z-index: 1;
  right: 12px;
  padding-right: 20px;
}
.slider__wrapper .slider__control .slider__arrow--right:hover {
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--right:active,
.slider__wrapper .slider__control .slider__arrow--right:visited,
.slider__wrapper .slider__control .slider__arrow--right:focus {
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--right.button--border {
  background: transparent;
  border: solid 1px #414141;
  color: #414141;
}
.slider__wrapper .slider__control .slider__arrow--right.button--border.active {
  border-color: black;
  color: white;
  background-color: black;
}
.slider__wrapper .slider__control .slider__arrow--right.button--noradius {
  border-radius: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient {
  position: relative;
  padding: 0;
  border: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient:visited,
.slider__wrapper .slider__control .slider__arrow--right.button--gradient:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient:hover .button__inner,
.slider__wrapper .slider__control .slider__arrow--right.button--gradient.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--gradient:hover .button__inner--hover,
.slider__wrapper .slider__control .slider__arrow--right.button--gradient.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download {
  position: relative;
  padding: 0;
  border: 0;
  line-height: 2.5em;
  border-radius: 0;
  border-bottom: solid 1px #11312a;
  font-weight: 300;
  font-size: 0.875em;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download .button__inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download .button__inner--hover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1em;
  line-height: 1em;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: -webkit-gradient(linear, left top, right top, from(#1b5f3d), color-stop(50%, #207052), to(#258267));
  background: -o-linear-gradient(left, #1b5f3d 0%, #207052 50%, #258267 100%);
  background: linear-gradient(to right, #1b5f3d 0%, #207052 50%, #258267 100%);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download span {
  position: relative;
  padding: 0 1.25em;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download:active {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--right.button--download:visited,
.slider__wrapper .slider__control .slider__arrow--right.button--download:focus {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
}
.slider__wrapper .slider__control .slider__arrow--right.button--download:hover .button__inner,
.slider__wrapper .slider__control .slider__arrow--right.button--download.active .button__inner {
  opacity: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--download:hover .button__inner--hover,
.slider__wrapper .slider__control .slider__arrow--right.button--download.active .button__inner--hover {
  opacity: 1;
}
.slider__wrapper .slider__control .slider__arrow--right.button--100 {
  width: 100%;
  text-align: center;
}
.slider__wrapper .slider__control .slider__arrow--right.button--color {
  background: green;
  border-color: green;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--right.button--color:hover {
  border-color: green;
  background: green;
}
.slider__wrapper .slider__control .slider__arrow--right.button--white {
  background: white;
  border-color: white;
  color: #414141;
  line-height: 2.188em;
  /*
        &:hover {
            border-color: @color_active;
            background: @color_active;
            color: white;
        }
        */
}
.slider__wrapper .slider__control .slider__arrow--right.button--white.active {
  border-color: black;
  background: black;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--right.button--arrow {
  position: relative;
  top: 8px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
}
.slider__wrapper .slider__control .slider__arrow--right.button--arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .slider__arrow--right.button--more {
  padding: 0 25px;
  border-radius: 0;
  background: white;
  color: gray;
  border: 0;
  line-height: 3em;
}
.slider__wrapper .slider__control .slider__arrow--right.button--more:hover {
  background: black;
  color: white;
}
.slider__wrapper .slider__control .slider__arrow--right::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 7px;
  border: solid 1px transparent;
  border-left-color: gray;
  border-bottom-color: gray;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.slider__wrapper .slider__control .slider__arrow--right:active,
.slider__wrapper .slider__control .slider__arrow--right:focus,
.slider__wrapper .slider__control .slider__arrow--right:visited {
  color: #414141;
}
.slider__wrapper .slider__control .slider__arrow--right::after {
  top: 4px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-left-color: gray;
  border-bottom-color: gray;
}
.slider__wrapper .slider__control .slider__arrow--right::after {
  left: initial;
  right: 5px;
  -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
          transform: rotate(-135deg);
}
.slider__wrapper .slider__control .slider__dots {
  text-align: center;
}
.slider__wrapper .slider__control .slider__dots ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.slider__wrapper .slider__control .slider__dots ul li:hover,
.slider__wrapper .slider__control .slider__dots ul li.slick-active {
  background-color: #1b5e3c;
}
.slider__wrapper .slider__control .slider__dots ul li button {
  display: none;
}
.slider__wrapper .slider__control .slider__nums {
  position: relative;
  width: 100%;
  text-align: center;
}
.slider__wrapper .slider__control .slider__nums .slider__nums__item {
  display: inline;
}
.slider__wrapper .slider__control .slider__nums .slider__nums__total {
  display: inline;
}
.slider__wrapper .slider__control .slider__nums .slider__nums__total::before {
  content: "/";
}
.slider__wrapper .slider__control.razdel__slider-control {
  width: 100%;
  height: 45px;
  background-color: #444444;
}
.slider__wrapper .slider__control.razdel__slider-control .slider__dots {
  padding-top: 12px;
}
.slider__wrapper .slider__control.razdel__slider-control .slider__dots ul li {
  background-color: white;
}
.slider__wrapper .slider__control.razdel__slider-control .slider__dots ul li:hover {
  -webkit-box-shadow: inset 1px -1px 0 0 #1b5e3c;
          box-shadow: inset 1px -1px 0 0 #1b5e3c;
}
.slider__wrapper .slider__control.razdel__slider-control .slider__dots ul li.slick-active {
  background-color: #1b5e3c;
}
.slider.slider--clients .item {
  height: 90px;
  text-align: center;
}
.slider.slider--clients .item .item__inner {
  display: inline-block;
}
.slider.slider--clients .item .item__inner .item__inner__table-cell {
  display: table-cell;
  height: 90px;
  vertical-align: middle;
}
.slider.slider--clients .item .item__inner .item__inner__table-cell img {
  max-width: 190px;
  max-height: 80px;
}
.slider.slider--clients .item .icon__wrapper {
  float: none;
  display: inline-block;
}
.slider.slider--clients .item .icon {
  margin: 0;
}
.slider.slider--razdel {
  border-top: solid 2px #444444;
}
.slider.slider--razdel .page-block {
  height: 440px;
}
.slider.slider--razdel .page-block ul li {
  font-size: 0.875em;
}
.slider .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
  border-left-color: #414141;
  border-bottom-color: #414141;
  cursor: pointer;
  z-index: 1;
}
.slider .slick-arrow.gallery__arrow--left {
  left: 0;
  -webkit-transform: rotation(45deg);
      -ms-transform: rotation(45deg);
          transform: rotation(45deg);
}
.slider .slick-arrow.gallery__arrow--right {
  right: 0;
  -webkit-transform: rotation(-135deg);
      -ms-transform: rotation(-135deg);
          transform: rotation(-135deg);
}
.content p,
.content ul,
.content ol,
.content table {
  margin-bottom: 20px;
  line-height: 1.3em;
}
.content h1 {
  margin: 0 0 20px;
}
.content h3 {
  font-weight: 500;
}
.content ul {
  list-style-type: disc;
  padding-left: 40px;
}
.content ul li {
  line-height: 1.3em;
}
.content u {
  text-decoration: underline;
}
.content dl dt {
  font-weight: 700;
  text-transform: uppercase;
}
.content dl dd {
  margin: 15px 0;
  padding-left: 15px;
}
.content img {
  margin: 10px 10px 10px 0;
}
.content table {
  display: table;
  border-collapse: collapse;
}
.content table tbody tr td {
  border: solid 1px #d1d1d1;
}
.content table .row {
  display: table-row;
}
.content table .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.content table td,
.content table th {
  padding: 20px;
  text-align: center;
}
.content table th {
  background-color: #258267;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
}
.content .button,
.content .button__inner,
.content .button__inner--hover {
  line-height: 45px;
  border-radius: 20px;
}
.content .button:hover,
.content .button__inner:hover,
.content .button__inner--hover:hover {
  color: white;
}
.content.content--special h2,
.content.content--special .h2 {
  margin: 1.6em 0 0.9em;
  color: #1b5e3c;
  text-transform: uppercase;
  font-size: 1.625em;
  font-weight: 700;
}
.content.content--special h2:first-child,
.content.content--special .h2:first-child {
  margin-top: 0;
}
.content.content--special ul {
  list-style-type: none;
}
.content.content--special ul li {
  margin: 0 0 0.7em;
}
.content.content--special ul li:before {
  content: "•";
  padding-right: 5px;
  color: #258267;
  font-size: 45px;
  position: relative;
  top: 7px;
}
@media only screen and (max-width: 960px) {
  .content table {
    width: 100%;
  }
  .content table td,
  .content table th {
    padding: 10px;
    font-size: 0.75em;
  }
  .content table th {
    font-size: 0.875em;
  }
}
/* Popup
*/
.popup {
  position: relative;
  cursor: pointer;
  /*
    &:hover {
        .popup__content {
            display: block;
        }
    } 
    */
}
.popup.active .popup__content {
  display: block;
}
.popup .popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.popup .popup__content {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  width: 195px;
  padding: 10px;
  background-color: #d1d1d1;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  color: #414141;
  z-index: 3;
}
.popup.popup--arrow .popup__content {
  position: absolute;
  top: 45px;
  width: auto;
  padding: 0 25px;
  background-color: white;
  font-size: 1em;
  line-height: 35px;
  font-style: normal;
  -webkit-box-shadow: 0 3px 5px 1px #d1d1d1;
          box-shadow: 0 3px 5px 1px #d1d1d1;
}
.popup.popup--arrow .popup__content::after {
  content: '';
  display: block;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 9px 10px 9px;
  border-color: transparent transparent #207052 transparent;
}
/* Tabs
*/
ul.tabs {
  margin-top: 105px;
  border-top: solid 3px #d1d1d1;
}
ul.tabs li.tabs__item a.tabs__item__title {
  float: left;
  position: relative;
  top: -88px;
  width: 25%;
  height: 80px;
  margin: 0;
  text-align: center;
  color: gray;
  border-bottom: solid 3px transparent;
  cursor: pointer;
}
ul.tabs li.tabs__item a.tabs__item__title.active {
  border-bottom-color: #1b5e3c;
}
ul.tabs li.tabs__item a.tabs__item__title.active + .tabs__item__content {
  display: block;
}
ul.tabs li.tabs__item .tabs__item__content {
  display: none;
  position: absolute;
  top: 20px;
  padding: 30px 0;
}
/*
правкращается в аккордион
@media only screen and (max-width: @media_step__md) {
    ul.tabs {
        margin-top: 0;

        li.tabs__item {
            a.tabs__item__title {
                display: block;
                float: none;                               
                top: 0;
                width: 100%;
                height: auto;
                margin: 0 auto;
                padding: 20px 0;
                font-size: @font_size__title;
                color: @color__active;  
            }
            
            .tabs__item__content {
                display: none;
                position: relative;
                top: 0;
            }
        }
    }
}
*/
/* BackToTop
*/
.back_to_top {
  position: fixed;
  right: 20px;
  bottom: 300px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: white;
  -webkit-box-shadow: 1px 1px 1px 1px #d1d1d1;
          box-shadow: 1px 1px 1px 1px #d1d1d1;
  opacity: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.back_to_top .icon {
  float: none;
  margin-right: 0;
}
/* Overlay
*/
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffffd6;
  overflow: auto;
  z-index: 5;
}
.overlay.transparent {
  background-color: rgba(2, 34, 58, 0);
}
/*
Formfield
*/
.formfield__container {
  margin: 20px 0;
}
.formfield__container:after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.formfield__container.formfield__container--ok {
  margin-bottom: 30px;
}
.formfield__container .formfield__title {
  float: left;
  width: 25%;
  margin-top: 8px;
  margin-right: 2%;
  font-weight: normal;
}
.formfield__container .formfield__field {
  position: relative;
  float: left;
  width: 73%;
}
.formfield__container .formfield__field.formfield__field--age .formfield__field__item input {
  width: 50px;
}
.formfield__container .formfield__field.disabled {
  font-size: 15px;
}
.formfield__container .formfield__field.disabled.formfield__field--age .formfield__field__item p {
  margin: 9px 0 0 -30px;
}
.formfield__container .formfield__field.disabled input {
  border: solid 1px transparent;
  background-color: transparent;
  cursor: text;
  font-size: 15px;
}
.formfield__container .formfield__field.disabled input:focus {
  border: 0;
}
.formfield__container .formfield__field.disabled .formfield__field__item.formfield__text {
  display: none;
}
.formfield__container .formfield__field.disabled .select2-container--default.select2-container--disabled .select2-selection--single {
  border: solid 1px transparent;
  background-color: transparent;
  font-size: 15px;
  cursor: initial;
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial;
}
.formfield__container .formfield__field.disabled .select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}
.formfield__container .formfield__field input {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  line-height: 40px;
  border: solid 1px #d1d1d1;
  cursor: pointer;
}
.formfield__container .formfield__field textarea {
  width: 100%;
  margin: 0;
  padding: 10px 10px;
  height: 120px;
  border: solid 1px #d1d1d1;
  cursor: pointer;
  resize: none;
}
.formfield__container .formfield__field .button {
  float: right;
}
.formfield__container .formfield__field .formfield__field__item__row:after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item {
  float: left;
  margin-right: 20px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.hidden input {
  position: absolute;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.formfield__text {
  width: 55%;
  padding-top: 5px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.formfield__text.formfield__text--button {
  width: 60%;
  padding-top: 0;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.formfield__text.formfield__text--button .button {
  margin: 0;
  line-height: 37px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.formfield__text.formfield__text--button span {
  display: inline-block;
  position: relative;
  top: 6px;
  left: 10px;
  width: 46%;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item.formfield__text span.error {
  position: relative;
  top: 8px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item:last-child {
  margin-right: 0;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item .radiocheck {
  margin-top: 8px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item select {
  width: auto;
  padding-right: 20px;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item input {
  width: auto;
}
.formfield__container .formfield__field .formfield__field__item__row .formfield__field__item p {
  margin: 8px 0 0 -10px;
}
.formfield__container .formfield__text {
  width: 43%;
  color: #a7a7a8;
  font-size: 0.875em;
  font-style: italic;
  line-height: 13px;
}
.formfield__container .formfield__text.error {
  position: absolute;
  bottom: -25px;
  width: 100%;
  padding: 5px;
  color: #842121;
  font-style: normal;
  font-size: 12px;
  z-index: 1;
}
.formfield__container .formfield__text.error span {
  color: inherit;
}
@media only screen and (max-width: 960px) {
  .formfield__container {
    margin-top: 15px;
  }
  .formfield__container .formfield__title {
    width: 100%;
    margin-bottom: 10px;
  }
  .formfield__container .formfield__field {
    width: 100%;
  }
}
body {
  padding-top: 144px;
}
.page {
  margin: 45px 0 60px;
}
/* Callback
*/
.callback.popup .popup__content {
  position: absolute;
  top: 45px;
  width: auto;
  padding: 0 25px;
  background-color: white;
  font-size: 1em;
  line-height: 35px;
  font-style: normal;
  -webkit-box-shadow: 0 3px 5px 1px #d1d1d1;
          box-shadow: 0 3px 5px 1px #d1d1d1;
}
.callback.popup .popup__content::after {
  content: '';
  display: block;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 9px 10px 9px;
  border-color: transparent transparent #207052 transparent;
}
.callback.popup .popup__content {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  left: 50%;
  width: 390px;
  margin-left: -195px;
  padding: 25px 10px 35px;
  text-align: left;
}
.callback.popup .popup__content input {
  width: 100%;
  margin: 15px 0 0;
  padding: 5px;
  height: 35px;
}
.callback.popup .popup__content textarea {
  width: 100%;
  height: 65px;
  margin: 15px 0 0;
  padding: 5px;
  resize: none;
}
.callback.popup .popup__content .button {
  float: right;
  margin-top: 10px;
  padding: 0 20px;
  color: white;
  border-color: white;
  border-radius: 0;
  line-height: 2.188em;
}
.callback.popup .popup__content .button:hover {
  background: white;
  color: #207052;
}
.callback.popup .popup__content .callback__city {
  margin-top: 10px;
  color: white;
}
.callback.popup .popup__content .callback__city a {
  color: white;
}
.callback.popup .popup__content .callback__city a .icon {
  display: inline-block;
  float: none;
  top: 3px;
}
.callback.popup .popup__content .callback__city a .icon.icon__mini_arrow {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.callback.popup .popup__content .callback__city.active a .icon.icon__mini_arrow {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.callback.popup .popup__content .callback__city ul {
  display: none;
}
.callback.popup .popup__content .callback__city ul li {
  margin-top: 10px;
  opacity: 0.4;
}
.callback.popup .popup__content .callback__city ul li:hover {
  opacity: 0.8;
}
/* Header
    .header
        .header__top
        .header__middle
        .header__bottom
*/
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 144px;
  background: white;
  z-index: 2;
}
.header.header--move {
  -webkit-box-shadow: 0 0 10px 1px #d1d1d1;
          box-shadow: 0 0 10px 1px #d1d1d1;
}
.header > .layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.header > .layout.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header > .layout.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header > .layout.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.header .header__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.header .header__top.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__top.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__top.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.header .header__middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 144px;
}
.header .header__middle.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__middle.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__middle.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.header .header__bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__bottom.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__bottom.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .header__bottom.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.header .header__logo {
  text-align: center;
  padding-right: 145px;
}
.header .header__logo .logo__img {
  width: 150px;
}
.header .header__logo .logo__subtitle {
  font-size: 0.655em;
  line-height: 1em;
  white-space: nowrap;
}
.header .icon__download {
  top: 6px;
  right: 12px;
}
.header .button--download {
  margin-left: 20px;
}
.header .nav {
  width: 63%;
  margin-right: 30px;
}
.header .nav .menu > ul > li {
  line-height: 75px;
  white-space: nowrap;
}
.header .nav .menu > ul > li:last-child {
  margin-right: 0;
}
.header .nav .menu > ul > li:hover > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.header .nav .menu > ul > li:hover > ul.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .nav .menu > ul > li:hover > ul.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .nav .menu > ul > li:hover > ul.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.header .nav .menu > ul > li .arrow {
  display: none;
}
.header .nav .menu > ul > li > ul {
  width: 960px;
  height: 395px;
  padding: 0 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  z-index: 2;
  -webkit-box-shadow: 0 1px 5px 2px #d1d1d1;
          box-shadow: 0 1px 5px 2px #d1d1d1;
}
.header .nav .menu > ul > li > ul > li {
  width: auto;
}
.header .nav .menu > ul > li > ul > li > a {
  margin-top: 20px;
  font-weight: 500;
  font-size: 0.875em;
}
.header .nav .menu > ul > li > ul > li > a:hover {
  font-weight: 500;
}
.header .nav .menu > ul > li > ul > li ul li a {
  font-size: 0.75em;
  font-weight: 300;
}
.header .nav .menu > ul > li > ul > li ul li a:hover {
  font-weight: 300;
  background: #ededed;
}
.header .buttons {
  white-space: nowrap;
}
.slider--main.slider--page .slider {
  height: 255px;
  min-height: 255px;
}
.slider--main.slider--page .slider .slider__item {
  height: 255px;
  min-height: 255px;
}
.slider--main.slider--page .slider .slider__item .slider__item__content {
  bottom: 0;
  padding: 0 3%;
}
.slider--main.slider--page .slider .slider__item .slider__item__content .slider__item__content__title {
  width: auto;
  font-size: 6.25em;
  line-height: 0.7;
  color: white;
}
.slider--main .slider {
  height: auto;
  min-height: 310px;
}
.slider--main .slider.slider--image img {
  width: 100%;
}
.slider--main .slider.slider--image .slider__item__content {
  position: absolute;
}
.slider--main .slider .slider__item {
  position: relative;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
  min-height: 310px;
  background-size: cover;
}
.slider--main .slider .slider__item .slider__item__content {
  padding: 0 8%;
}
.slider--main .slider .slider__item .slider__item__content .slider__item__content__title {
  width: 50%;
  font-weight: 800;
  font-size: 3.6875em;
  text-transform: uppercase;
  line-height: 1;
  color: #131313;
}
.slider--main .slider .slider__item .slider__item__content .slider__item__content__subtitle {
  margin-top: 20px;
}
.slider--main .slider .slider__item .slider__item__content .slider__item__button {
  margin-top: 50px;
}
.slider--main .slider__control {
  position: absolute;
  left: 0;
  bottom: 0;
}
.slider--main .slider__control .slider__dots {
  position: relative;
  margin-left: 35px;
  margin-bottom: 35px;
}
.slider--main .slider__control .slider__dots ul.slick-dots {
  position: absolute;
  bottom: 0;
}
.slider--main .slider__control .slider__dots ul.slick-dots li {
  display: block;
  width: 12px;
  height: 12px;
  margin: 18px auto;
  border: solid 1px white;
  background: transparent;
}
.slider--main .slider__control .slider__dots ul.slick-dots li::before {
  content: "";
  display: block;
  position: relative;
  top: -1px;
  left: -1px;
  width: 10px;
  height: 10px;
  background: transparent;
  border-radius: 50%;
  border: solid 1px white;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.slider--main .slider__control .slider__dots ul.slick-dots li:hover {
  background: white;
}
.slider--main .slider__control .slider__dots ul.slick-dots li.slick-active {
  background: white;
}
.slider--main .slider__control .slider__dots ul.slick-dots li.slick-active::before {
  top: -6px;
  left: -6px;
  width: 20px;
  height: 20px;
}
.slider--main .slider__control .slider__arrows__wrapper {
  margin-bottom: 20px;
}
.slider--main .slider__control .slider__arrows__wrapper > div {
  display: block;
  position: relative;
  float: left;
  margin-right: 0.5em;
  float: none;
  left: 0;
  right: 0;
  margin: 4px 0;
  border-radius: 0;
}
.slider--main .slider__control .slider__arrows__wrapper > div.icon--right {
  float: right;
  margin-right: 0;
  margin-left: 0.5em;
}
.slider--main .slider__control .slider__arrows__wrapper > div::after {
  display: none;
}
.slider--main .slider__control .slider__arrows__wrapper > div.slick-disabled {
  opacity: 0.5;
}
.slider--main .slider__control .slider__arrows__wrapper .slider__arrow--left {
  background-image: url(/assets/static/img/sprite.png);
  background-position: -52px -169px;
  width: 51px;
  height: 30px;
}
.slider--main .slider__control .slider__arrows__wrapper .slider__arrow--right {
  background-image: url(/assets/static/img/sprite.png);
  background-position: 0px -169px;
  width: 51px;
  height: 30px;
}
.catalog.category .catalog__sections {
  width: 100%;
  margin: 0 0 60px;
}
.catalog.category .catalog__sections .catalog__sections__item {
  width: 25%;
  height: 355px;
}
.catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner {
  padding: 0;
}
.catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__icon {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
}
.catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner p {
  width: 100%;
  margin: 0;
  min-height: 55px;
  padding: 5px 0;
  background-color: white;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner:hover p {
  background-color: #258267;
  color: white;
}
.catalog .catalog__sections {
  width: 94%;
  margin: 0 3% 60px;
}
.catalog .catalog__sections .row.template .catalog__sections__item {
  height: 0;
  border: 0;
}
.catalog .catalog__sections .catalog__sections__item {
  position: relative;
  width: 33.33333333%;
  height: 290px;
  overflow: hidden;
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner {
  background: #00000080;
  color: white;
}
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner .catalog__sections__item__plus {
  opacity: 0;
}
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner .catalog__sections__item__arrow {
  right: 20px;
  opacity: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg path,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg ellipse,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg polyline,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg line,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg text,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg rect,
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__inner svg polygon {
  stroke: white;
}
.catalog .catalog__sections .catalog__sections__item:hover .catalog__sections__item__bg {
  opacity: 0.8;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .icon_temp {
  position: absolute;
  top: 20px;
  right: 20px;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__plus {
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url(/assets/static/img/sprite.png);
  background-position: -131px -169px;
  width: 25px;
  height: 25px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url(/assets/static/img/sprite.png);
  background-position: -171px -62px;
  width: 44px;
  height: 32px;
  right: 40px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__icon {
  width: 300px;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg {
  width: 40%;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg path,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg ellipse,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg polyline,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg line,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg text,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg rect,
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner svg polygon {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner p {
  margin: 1em 0;
  font-weight: 400;
  line-height: 1.17em;
  text-transform: none;
  font-size: 1.75em;
  width: 60%;
  margin: 0.5em 0;
}
.catalog .catalog__sections .catalog__sections__item .catalog__sections__item__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 225px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.poster {
  height: 310px;
  color: white;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.poster a {
  color: white;
}
.poster .poster__text1 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 2.75em;
}
.poster .poster__text2 {
  font-size: 1.188em;
}
.poster .poster__text3 {
  margin: 30px 0;
  font-weight: 700;
  font-size: 3.75em;
}
.about {
  margin-bottom: 80px;
}
.about .about__text {
  width: 47%;
  padding-right: 5%;
}
.about .about__service {
  position: relative;
  top: 20px;
  width: 210px;
  height: 240px;
  margin-top: 35px;
  margin-left: 5px;
  padding-top: 55px;
  background: url('/assets/static/img/poligon.svg') no-repeat 100%;
  text-align: center;
}
.about .about__service:nth-child(2),
.about .about__service.first {
  padding-top: 78px;
}
.about .about__service:last-child,
.about .about__service.third {
  padding-top: 62px;
}
.about .about__service .icon {
  display: inline-block;
  float: none;
  margin: 0 0 15px;
}
.footer {
  background: -webkit-gradient(linear, left top, right top, from(#258267), color-stop(50%, #207052), to(#1b5f3d));
  background: -o-linear-gradient(left, #258267 0%, #207052 50%, #1b5f3d 100%);
  background: linear-gradient(to right, #258267 0%, #207052 50%, #1b5f3d 100%);
  height: 270px;
  padding-top: 20px;
  color: white;
}
.footer .footer__right {
  padding-top: 15px;
  text-align: right;
}
.footer a {
  color: white;
}
.footer .layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: normal;
  -webkit-align-items: normal;
      -ms-flex-align: normal;
          align-items: normal;
}
.footer .layout.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer .layout.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer .layout.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.footer .logo {
  margin-bottom: 30px;
}
.footer .phone {
  display: block;
  margin-bottom: 12px;
  font-size: 1.5em;
  font-weight: 700;
}
.footer .email {
  display: block;
  margin-bottom: 25px;
}
.footer .copyright {
  font-size: 0.875em;
}
.footer .short__info {
  margin-bottom: 5px;
  font-size: 1.125em;
}
.footer .button {
  margin: 20px 0 15px;
  padding: 0 22px;
  border-color: white;
  color: white;
  border-radius: 0;
  line-height: 2.188;
}
.footer .social a {
  margin-left: 1em;
}
.footer .social a .icon {
  display: inline-block;
  float: none;
  margin-right: 0;
}
.product .product__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-left: 3%;
  margin-bottom: 60px;
}
.product .product__inner.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.product .product__inner.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.product .product__inner.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.product .product__inner .product__content {
  width: 89%;
}
.product .product__inner .product__content .product__content__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.product .product__inner .product__content .product__content__top.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.product .product__inner .product__content .product__content__top.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.product .product__inner .product__content .product__content__top.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.product .product__inner .product__content .product__content__top .product__gallery {
  width: 32%;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper {
  position: relative;
  width: 100%;
  height: 390px;
  border: solid 1px #d1d1d1;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .icon__glass {
  position: absolute;
  top: 5px;
  right: 0;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper iframe {
  height: 390px;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .product__gallery__tags {
  position: absolute;
  top: 0;
  left: 0;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .product__gallery__tags .product__gallery__tag {
  width: 110px;
  height: 30px;
  margin-bottom: 3px;
  text-align: center;
  line-height: 30px;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .product__gallery__tags .product__gallery__tag.product__gallery__tag--sale {
  background-color: #1b5e3c;
  color: white;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .product__gallery__tags .product__gallery__tag.product__gallery__tag--warm {
  background-color: #f4f4f4;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .product__gallery__tags .product__gallery__tag.product__gallery__tag--hit {
  background-color: #1b5e3c;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .slider .item {
  display: block;
  position: relative;
  height: 388px;
  overflow: hidden;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .slider .item img {
  position: absolute;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper .slider .item iframe {
  height: 388px;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs {
  position: relative;
  width: 100%;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider {
  width: 280px;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider .slick-track {
  margin: inherit;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider .slick-list {
  padding: 1px 0;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider .item {
  display: block;
  position: relative;
  width: 90px;
  height: 70px;
  margin: 0 2px;
  overflow: hidden;
  border: solid 3px white;
  border-top-width: 5px;
  border-bottom-width: 5px;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider .item.slick-current {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #1b5e3c;
          box-shadow: 0 0 0 1px #1b5e3c;
}
.product .product__inner .product__content .product__content__top .product__gallery .product__gallery__thumbs .slider .item img {
  position: absolute;
}
.product .product__inner .product__content .product__content__top .product__desc {
  width: 65%;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager {
  display: none;
  position: absolute;
  width: 680px;
  padding: 35px;
  -webkit-box-shadow: 0 0 10px 1px #d1d1d1;
          box-shadow: 0 0 10px 1px #d1d1d1;
  background: white;
  z-index: 3;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .h1 {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 700;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager small {
  display: block;
  margin-bottom: 20px;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item {
  margin-top: 35px;
  padding-left: 15px;
  border-left: solid 2px #414141;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item p,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item ul,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item ol,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table {
  margin-bottom: 20px;
  line-height: 1.3em;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item h1 {
  margin: 0 0 20px;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item h3 {
  font-weight: 500;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item ul {
  list-style-type: disc;
  padding-left: 40px;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item ul li {
  line-height: 1.3em;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item u {
  text-decoration: underline;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item dl dt {
  font-weight: 700;
  text-transform: uppercase;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item dl dd {
  margin: 15px 0;
  padding-left: 15px;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item img {
  margin: 10px 10px 10px 0;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table {
  display: table;
  border-collapse: collapse;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table tbody tr td {
  border: solid 1px #d1d1d1;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table .row {
  display: table-row;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table td,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table th {
  padding: 20px;
  text-align: center;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item table th {
  background-color: #258267;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button__inner,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button__inner--hover {
  line-height: 45px;
  border-radius: 20px;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button:hover,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button__inner:hover,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item .button__inner--hover:hover {
  color: white;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special h2,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special .h2 {
  margin: 1.6em 0 0.9em;
  color: #1b5e3c;
  text-transform: uppercase;
  font-size: 1.625em;
  font-weight: 700;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special h2:first-child,
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special .h2:first-child {
  margin-top: 0;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special ul {
  list-style-type: none;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special ul li {
  margin: 0 0 0.7em;
}
.product .product__inner .product__content .product__content__top .product__desc .connect_with_manager .contacts__managers__item.content--special ul li:before {
  content: "•";
  padding-right: 5px;
  color: #258267;
  font-size: 45px;
  position: relative;
  top: 7px;
}
.product .product__inner .product__content .product__content__top .product__desc .button {
  margin-top: 40px;
}
.product .product__inner .product__content .product__content__top .product__desc .button,
.product .product__inner .product__content .product__content__top .product__desc .button__inner,
.product .product__inner .product__content .product__content__top .product__desc .button__inner--hover {
  line-height: 45px;
  border-radius: 20px;
}
.product .product__inner .product__content .product__content__top .product__desc .button:hover,
.product .product__inner .product__content .product__content__top .product__desc .button__inner:hover,
.product .product__inner .product__content .product__content__top .product__desc .button__inner--hover:hover {
  color: white;
}
.product .product__inner .product__content .product__content__bottom {
  position: relative;
  margin-bottom: 30px;
}
.product .product__inner .product__content .product__content__bottom .tabs .tabs__item .tabs__item__title {
  top: -60px;
  width: auto;
  height: 60px;
  padding: 0 15px;
  line-height: 60px;
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: 700;
}
.product .product__inner .product__content .product__content__bottom .tabs .tabs__item .tabs__item__title.active {
  color: #414141;
}
.product .product__inner .product__other_products {
  width: 10%;
}
.product .product__inner .product__other_products .product__other_products__item {
  display: block;
  width: 100%;
  height: 132px;
  margin-top: 15px;
  border: solid 1px #d1d1d1;
  overflow: hidden;
}
.product .product__inner .product__other_products .product__other_products__item .product__other_products__item__inner {
  padding: 0;
}
.product .product__inner .product__other_products .product__other_products__item .product__other_products__item__inner .product__other_products__item__icon {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center center;
}
.product .product__inner .product__other_products .product__other_products__item .product__other_products__item__inner p {
  width: 100%;
  margin: 0;
  min-height: 30px;
  padding: 1px 0;
  background-color: white;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
  text-align: center;
  line-height: 0.9em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.product .product__inner .product__other_products .product__other_products__item .product__other_products__item__inner:hover p {
  background-color: #258267;
  color: white;
}
.contacts {
  margin: 15px auto 60px;
}
.contacts .layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.contacts .layout.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.contacts .layout.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.contacts .layout.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.contacts .contacts__title {
  margin-bottom: 10px;
  font-size: 1.875em;
  font-weight: 800;
  text-transform: uppercase;
}
.contacts .contacts__title .icon {
  display: inline-block;
  position: relative;
  float: none;
  margin-left: 0.7em;
  margin-right: 0.2em;
  top: 3px;
}
.contacts .contacts__info {
  width: 70%;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data p,
.contacts .contacts__info .contacts__info__item .contacts__info__data ul,
.contacts .contacts__info .contacts__info__item .contacts__info__data ol,
.contacts .contacts__info .contacts__info__item .contacts__info__data table {
  margin-bottom: 20px;
  line-height: 1.3em;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data h1 {
  margin: 0 0 20px;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data h3 {
  font-weight: 500;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data ul {
  list-style-type: disc;
  padding-left: 40px;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data ul li {
  line-height: 1.3em;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data u {
  text-decoration: underline;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data dl dt {
  font-weight: 700;
  text-transform: uppercase;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data dl dd {
  margin: 15px 0;
  padding-left: 15px;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data img {
  margin: 10px 10px 10px 0;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table {
  display: table;
  border-collapse: collapse;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table tbody tr td {
  border: solid 1px #d1d1d1;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table .row {
  display: table-row;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table td,
.contacts .contacts__info .contacts__info__item .contacts__info__data table th {
  padding: 20px;
  text-align: center;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data table th {
  background-color: #258267;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data .button,
.contacts .contacts__info .contacts__info__item .contacts__info__data .button__inner,
.contacts .contacts__info .contacts__info__item .contacts__info__data .button__inner--hover {
  line-height: 45px;
  border-radius: 20px;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data .button:hover,
.contacts .contacts__info .contacts__info__item .contacts__info__data .button__inner:hover,
.contacts .contacts__info .contacts__info__item .contacts__info__data .button__inner--hover:hover {
  color: white;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special h2,
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special .h2 {
  margin: 1.6em 0 0.9em;
  color: #1b5e3c;
  text-transform: uppercase;
  font-size: 1.625em;
  font-weight: 700;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special h2:first-child,
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special .h2:first-child {
  margin-top: 0;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special ul {
  list-style-type: none;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special ul li {
  margin: 0 0 0.7em;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data.content--special ul li:before {
  content: "•";
  padding-right: 5px;
  color: #258267;
  font-size: 45px;
  position: relative;
  top: 7px;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data > div {
  width: 49%;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data .contacts__info__data__title {
  font-size: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
}
.contacts .contacts__info .contacts__info__item .contacts__info__data .contacts__info__data__map {
  width: 100%;
  height: 300px;
  border: solid 1px #d1d1d1;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.contacts .contacts__managers {
  width: 28%;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
}
.contacts .contacts__managers .contacts__managers__item {
  margin-top: 35px;
  padding-left: 15px;
  border-left: solid 2px #414141;
}
.contacts .contacts__managers .contacts__managers__item p,
.contacts .contacts__managers .contacts__managers__item ul,
.contacts .contacts__managers .contacts__managers__item ol,
.contacts .contacts__managers .contacts__managers__item table {
  margin-bottom: 20px;
  line-height: 1.3em;
}
.contacts .contacts__managers .contacts__managers__item h1 {
  margin: 0 0 20px;
}
.contacts .contacts__managers .contacts__managers__item h3 {
  font-weight: 500;
}
.contacts .contacts__managers .contacts__managers__item ul {
  list-style-type: disc;
  padding-left: 40px;
}
.contacts .contacts__managers .contacts__managers__item ul li {
  line-height: 1.3em;
}
.contacts .contacts__managers .contacts__managers__item u {
  text-decoration: underline;
}
.contacts .contacts__managers .contacts__managers__item dl dt {
  font-weight: 700;
  text-transform: uppercase;
}
.contacts .contacts__managers .contacts__managers__item dl dd {
  margin: 15px 0;
  padding-left: 15px;
}
.contacts .contacts__managers .contacts__managers__item img {
  margin: 10px 10px 10px 0;
}
.contacts .contacts__managers .contacts__managers__item table {
  display: table;
  border-collapse: collapse;
}
.contacts .contacts__managers .contacts__managers__item table tbody tr td {
  border: solid 1px #d1d1d1;
}
.contacts .contacts__managers .contacts__managers__item table .row {
  display: table-row;
}
.contacts .contacts__managers .contacts__managers__item table .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.contacts .contacts__managers .contacts__managers__item table td,
.contacts .contacts__managers .contacts__managers__item table th {
  padding: 20px;
  text-align: center;
}
.contacts .contacts__managers .contacts__managers__item table th {
  background-color: #258267;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
}
.contacts .contacts__managers .contacts__managers__item .button,
.contacts .contacts__managers .contacts__managers__item .button__inner,
.contacts .contacts__managers .contacts__managers__item .button__inner--hover {
  line-height: 45px;
  border-radius: 20px;
}
.contacts .contacts__managers .contacts__managers__item .button:hover,
.contacts .contacts__managers .contacts__managers__item .button__inner:hover,
.contacts .contacts__managers .contacts__managers__item .button__inner--hover:hover {
  color: white;
}
.contacts .contacts__managers .contacts__managers__item.content--special h2,
.contacts .contacts__managers .contacts__managers__item.content--special .h2 {
  margin: 1.6em 0 0.9em;
  color: #1b5e3c;
  text-transform: uppercase;
  font-size: 1.625em;
  font-weight: 700;
}
.contacts .contacts__managers .contacts__managers__item.content--special h2:first-child,
.contacts .contacts__managers .contacts__managers__item.content--special .h2:first-child {
  margin-top: 0;
}
.contacts .contacts__managers .contacts__managers__item.content--special ul {
  list-style-type: none;
}
.contacts .contacts__managers .contacts__managers__item.content--special ul li {
  margin: 0 0 0.7em;
}
.contacts .contacts__managers .contacts__managers__item.content--special ul li:before {
  content: "•";
  padding-right: 5px;
  color: #258267;
  font-size: 45px;
  position: relative;
  top: 7px;
}
.services .layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.services .layout.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.services .layout.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.services .layout.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.services .layout .services__menu {
  width: 20%;
}
.services .layout .services__menu ul {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.services .layout .services__menu ul tbody tr td {
  border: solid 1px #d1d1d1;
}
.services .layout .services__menu ul .row {
  display: table-row;
}
.services .layout .services__menu ul .cell {
  display: table-cell;
  border: solid 1px #d1d1d1;
}
.services .layout .services__menu ul li {
  display: table-row;
}
.services .layout .services__menu ul li .button {
  display: table-cell;
  width: 100%;
  line-height: 50px;
  text-align: center;
  border: solid 1px #d1d1d1;
  font-weight: 400;
}
.services .layout .services__menu ul li a {
  display: table-cell;
  width: 100%;
  line-height: 50px;
  text-align: center;
  border: solid 1px #d1d1d1;
}
.services .layout .services__content {
  width: 78%;
}
.services .layout .services__content form {
  width: 80%;
}
.services .layout .services__content .buttom {
  margin-left: 0;
}
.promotions__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 50px;
}
.promotions__item.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.promotions__item.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.promotions__item.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.promotions__item .promotions__item__img {
  position: relative;
  width: 28%;
  height: 300px;
  overflow: hidden;
}
.promotions__item .promotions__item__content {
  width: 70%;
}
.promotions__item .promotions__item__content h2,
.promotions__item .promotions__item__content .h2 {
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 2.6875em;
  text-transform: uppercase;
}
.promotion .promotion__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.promotion .promotion__inner.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.promotion .promotion__inner.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.promotion .promotion__inner.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.promotion .promotion__other {
  width: 18%;
}
.promotion .promotion__other .promotion__other__title {
  text-transform: uppercase;
}
.promotion .promotion__other .product__other_products__item {
  display: block;
  width: 100%;
  height: 245px;
  margin-top: 15px;
  border: solid 1px #d1d1d1;
  overflow: hidden;
}
.promotion .promotion__other .product__other_products__item .product__other_products__item__inner {
  padding: 0;
}
.promotion .promotion__other .product__other_products__item .product__other_products__item__inner .product__other_products__item__icon {
  width: 100%;
  height: 183px;
  background-size: cover;
  background-position: center center;
}
.promotion .promotion__other .product__other_products__item .product__other_products__item__inner p {
  width: 100%;
  margin: 0;
  min-height: 60px;
  padding: 1px 0;
  background-color: white;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
  text-align: center;
  line-height: 0.9em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
}
.promotion .promotion__other .product__other_products__item .product__other_products__item__inner:hover p {
  background-color: #258267;
  color: white;
}
.promotion .promotions__item {
  width: 80%;
}
.promotion .promotions__item .promotions__item__content h1,
.promotion .promotions__item .promotions__item__content .h1 {
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 1.875em;
  text-transform: uppercase;
}
.promotion .promotions__item .promotions__item__content h2,
.promotion .promotions__item .promotions__item__content .h2 {
  margin: 0 0 15px;
  font-size: 1.25em;
}
.about.page h2,
.about.page .h2 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 1.875em;
  text-transform: uppercase;
}
.about.page h3,
.about.page .h3 {
  margin: 0 0 20px;
  font-size: 1.875em;
  text-transform: uppercase;
  color: #258267;
}
.about.page .about__slogans {
  text-align: center;
  font-size: 1.875em;
  font-weight: 500;
}
.about.page .about__slogans p {
  margin-bottom: 40px;
}
.about.page .about__services {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0px 0 100px;
}
.about.page .about__services.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__services.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__services.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.about.page .about__services .about__service {
  width: 300px;
  height: 330px;
  margin: 0 30px;
  font-size: 1.4375em;
}
.about.page .about__services .about__service.first {
  padding-top: 95px;
}
.about.page .about__services .about__service.second {
  padding-top: 65px;
}
.about.page .about__services .about__service.third {
  padding-top: 72px;
}
.about.page .about__header {
  width: 100%;
  height: 65px;
}
.about.page .about__header.grey {
  background: #d1d1d1;
}
.about.page .about__header h2 {
  margin: 0 auto;
  line-height: 65px;
  text-align: center;
}
.about.page .about__give {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 40px 0 40px;
}
.about.page .about__give.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__give.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__give.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.about.page .about__give > div {
  width: 49%;
  font-size: 1.125em;
}
.about.page .about__give > div ul {
  padding-left: 20px;
}
.about.page .about__give > div ul li::before {
  content: "•";
  padding-right: 5px;
  color: #258267;
  font-size: 35px;
  position: relative;
  top: 7px;
}
.about.page .about__callback {
  padding-bottom: 45px;
}
.about.page .about__callback p {
  margin-bottom: 20px;
  font-size: 1.125em;
  text-align: center;
}
.about.page .about__callback .about__callback__buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.about.page .about__callback .about__callback__buttons.flex--default {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__callback .about__callback__buttons.flex--table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.about.page .about__callback .about__callback__buttons.flex--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.about.page .about__callback .about__callback__buttons .popup__content {
  top: 56px;
}
.about.page .about__callback .about__callback__buttons .button.button--gradient {
  margin: 0 10px;
  font-weight: 400;
}
.about.page .about__callback .about__callback__buttons .button.button--gradient,
.about.page .about__callback .about__callback__buttons .button.button--gradient .button__inner,
.about.page .about__callback .about__callback__buttons .button.button--gradient .button__inner--hover {
  line-height: 45px;
}
.about.page .about__callback .about__callback__buttons .button.button--gradient:hover,
.about.page .about__callback .about__callback__buttons .button.button--gradient .button__inner:hover,
.about.page .about__callback .about__callback__buttons .button.button--gradient .button__inner--hover:hover {
  color: white;
}
.about.page .about__clients {
  padding-top: 20px;
}
.about.page .about__clients .clients {
  width: 100%;
}
.about.page .about__clients .clients .clients__item {
  position: relative;
  height: 200px;
}
.about.page .about__clients .clients .clients__item .clients__item__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: white;
  border: solid 1px white;
}
.about.page .about__clients .clients .clients__item .clients__item__img img {
  position: absolute;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.about.page .about__clients .clients .clients__item .clients__item__img:hover {
  -webkit-transform: scale(1.05, 1.1);
      -ms-transform: scale(1.05, 1.1);
          transform: scale(1.05, 1.1);
  border-color: #d1d1d1;
  z-index: 1;
}
.about.page .about__clients .clients .clients__item .clients__item__img:hover img {
  -webkit-filter: none;
          filter: none;
}
@media only screen and (max-width: 1440px) and (min-width: 1201px) {
  .header {
    font-size: 14px;
  }
  .header .header__logo {
    padding-right: 47px;
  }
}
@media only screen and (max-width: 1200px) {
  .callback__button {
    font-size: 0.875em;
  }
  .header .layout {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header .logo .logo__img {
    display: none;
  }
  .header .logo .logo__img.logo__img--mini {
    display: inline-block;
    width: 295px;
  }
  .header .logo .logo__subtitle {
    position: relative;
    top: -18px;
    font-size: 0.92em;
    line-height: 1em;
    white-space: nowrap;
    text-align: right;
  }
  .header .sandwich {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    display: block;
  }
  .header .button--download {
    margin-left: 3px;
  }
  .header .minimodal {
    position: absolute;
    width: 100%;
    top: 120px;
    left: 0;
    background: white;
    padding: 10px 3% 25px;
  }
  .header__middle {
    top: 100px;
    font-size: 15px;
    -webkit-box-shadow: 0 0 10px 1px #d1d1d1;
            box-shadow: 0 0 10px 1px #d1d1d1;
    z-index: 3;
  }
  .header__top {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
  .nav {
    margin-right: 0;
  }
  .nav .menu > ul > li {
    position: static;
    margin-right: 25px;
  }
  .nav .menu > ul > li:hover ul {
    left: 0;
    width: 100%;
  }
  .slider--main .slider .slider__item .slider__item__content .slider__item__content__title {
    font-size: 2.75em;
  }
  .catalog .layout {
    padding: 0;
  }
  .catalog .h1 {
    padding: 0 3%;
  }
  .catalog .catalog__sections {
    width: 100%;
    margin: 0 0 60px;
  }
  .catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner p {
    width: 100%;
  }
  .about .about__service {
    position: absolute;
    font-size: 14px;
  }
  .about .about__service:nth-child(2),
  .about .about__service.first {
    top: -10px;
    right: 180px;
  }
  .about .about__service:nth-child(3),
  .about .about__service.second {
    top: 175px;
    right: 293px;
  }
  .about .about__service:nth-child(4),
  .about .about__service.third {
    top: 175px;
    right: 65px;
  }
  .product .breadcrumbs {
    padding: 10px 0;
  }
  .product .product__inner {
    display: block;
    padding-left: 0;
  }
  .product .product__inner .product__content {
    width: 100%;
  }
  .product .product__inner .product__other_products {
    width: 100%;
  }
  .product .product__inner .product__other_products .product__other__products__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .product .product__inner .product__other_products .product__other__products__inner.flex--default {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .product .product__inner .product__other_products .product__other__products__inner.flex--table {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .product .product__inner .product__other_products .product__other__products__inner.flex--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
  .product .product__inner .product__other_products .product__other_products__item {
    width: 200px;
    margin-right: 10px;
  }
  .promotion .breadcrumbs {
    padding: 10px 0;
  }
  .promotion .promotion__inner {
    display: block;
  }
  .promotion .promotion__inner .promotions__item {
    width: 100%;
    margin-bottom: 20px;
  }
  .promotion .promotion__inner .promotion__other {
    width: 100%;
  }
  .promotion .promotion__inner .promotion__other .product__other__products__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .promotion .promotion__inner .promotion__other .product__other__products__inner.flex--default {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .promotion .promotion__inner .promotion__other .product__other__products__inner.flex--table {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .promotion .promotion__inner .promotion__other .product__other__products__inner.flex--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
  .promotion .promotion__inner .promotion__other .product__other_products__item {
    width: 230px;
    height: 190px;
    margin-right: 10px;
  }
  .promotion .promotion__inner .promotion__other .product__other_products__item .product__other_products__item__icon {
    width: 100%;
    height: 128px;
  }
  .breadcrumbs {
    padding: 10px 3%;
  }
  .contacts .contacts__info .contacts__info__item .contacts__info__data {
    display: block;
  }
  .contacts .contacts__info .contacts__info__item .contacts__info__data > div {
    width: 100%;
  }
  .about.page {
    font-size: 14px;
  }
  .about.page .about__services {
    padding-bottom: 225px;
  }
  .about.page .about__services .about__service {
    position: relative;
    width: 210px;
    height: 240px;
    font-size: 14px;
  }
  .about.page .about__services .about__service .icon__delivery--l {
    background-image: url(/assets/static/img/sprite.png);
    background-position: -80px -112px;
    width: 62px;
    height: 38px;
  }
  .about.page .about__services .about__service .icon__solution--l {
    background-image: url(/assets/static/img/sprite.png);
    background-position: -171px 0px;
    width: 47px;
    height: 61px;
  }
  .about.page .about__services .about__service .icon__person--l {
    background-image: url(/assets/static/img/sprite.png);
    background-position: -80px -56px;
    width: 55px;
    height: 55px;
  }
  .about.page .about__services .about__service.first {
    top: 0;
    left: 270px;
    right: auto;
    padding-top: 80px;
  }
  .about.page .about__services .about__service.second {
    right: 115px;
    top: 190px;
  }
  .about.page .about__services .about__service.third {
    right: 153px;
    top: 190px;
  }
}
@media only screen and (max-width: 960px) {
  body {
    padding-top: 95px;
  }
  h1,
  .h1 {
    font-size: 1.625em;
  }
  .header {
    height: 95px;
  }
  .header .logo {
    padding: 0;
  }
  .header .logo .logo__img.logo__img--mini {
    width: 180px;
  }
  .header .logo .logo__subtitle {
    top: -13px;
    font-size: 0.56em;
  }
  .header .nav .menu > ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .header .nav .menu > ul > li {
    line-height: 2em;
  }
  .header .nav .menu > ul > li.active a::before {
    top: 0;
    left: 0;
    width: 3px;
    height: 25px;
  }
  .header .nav .menu > ul > li:hover > ul {
    display: none;
  }
  .header .nav .menu > ul > li a {
    padding-left: 10px;
  }
  .header .nav .menu > ul > li .arrow {
    display: inline-block;
    top: 0;
    left: 10px;
    border-color: #414141;
  }
  .header .nav .menu > ul > li .arrow.accordion_plus::after {
    display: none;
  }
  .header .nav .menu > ul > li .arrow.accordion_plus::before {
    top: 0px;
    border-style: solid;
    border-width: 5px 8px 5px 8px;
    border-color: transparent transparent transparent #1b5e3c;
  }
  .header .nav .menu > ul > li .arrow.accordion_minus {
    top: 3px;
    left: 8px;
  }
  .header .nav .menu > ul > li .arrow.accordion_minus::after {
    display: none;
  }
  .header .nav .menu > ul > li .arrow.accordion_minus::before {
    top: 5px;
    margin-right: 7px;
    border-style: solid;
    border-width: 8px 5px 8px 5px;
    border-color: #1b5e3c transparent transparent transparent;
  }
  .header .nav .menu > ul > li > ul {
    position: relative;
    height: auto;
    padding: 0 3%;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .header .nav .menu > ul > li > ul li a {
    margin-top: 5px;
  }
  .header .nav .menu > ul > li > ul li ul li a {
    padding: 2px 7px;
  }
  .header .buttons {
    width: 100%;
    margin: 10px 0;
  }
  .header .buttons .button {
    font-size: 0.75em;
  }
  .header .buttons .button:first-child {
    margin-right: 5px;
  }
  .header .header__top {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .header .header__top .popup {
    position: static;
  }
  .header .header__top .popup .popup__content {
    width: 106.5%;
    left: -3%;
    margin-left: 0;
  }
  .header .header__top .popup .popup__content::after {
    left: 15%;
  }
  .header .header__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .header .minimodal {
    top: 90px;
    height: auto;
    font-size: 16px;
    padding: 10px 0 25px;
  }
  .header .minimodal [data-scrolly-viewport] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 3%;
  }
  .header .minimodal [data-scrolly-viewport].flex--default {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header .minimodal [data-scrolly-viewport].flex--table {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header .minimodal [data-scrolly-viewport].flex--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
  .header .minimodal [data-scrolly-viewport] .header__top .short__info span:last-child {
    height: 1.4em;
  }
  .header .minimodal [data-scrolly-viewport] .header__bottom {
    display: block;
  }
  .header .popup {
    margin: 0 0 15px;
  }
  .header .short__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    margin: 15px 0;
  }
  .header .short__info span:last-child {
    position: relative;
    top: -4px;
    padding: 2px 5px;
    border: solid 1px #414141;
    border-radius: 20px;
  }
  .slider--main.slider--page .slider {
    height: 150px;
    min-height: 150px;
  }
  .slider--main.slider--page .slider .slider__item {
    height: 150px;
    min-height: 150px;
  }
  .slider--main.slider--page .slider .slider__item .slider__item__content {
    top: auto;
  }
  .slider--main.slider--page .slider .slider__item .slider__item__content .slider__item__content__title {
    font-size: 2.5em;
  }
  .slider--main .slider {
    height: 310px;
  }
  .slider--main .slider .slider__item {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 310px;
    overflow: hidden;
  }
  .slider--main .slider .slider__item img {
    position: absolute;
  }
  .slider--main .slider .slider__item .slider__item__content {
    top: 30px;
  }
  .slider--main .slider .slider__item .slider__item__content .slider__item__content__title {
    font-size: 1.7em;
  }
  .slider--main .slider .slider__item .slider__item__content .slider__item__button {
    margin-top: 35px;
  }
  .slider--main .slider__control {
    bottom: -10px;
    width: 100%;
  }
  .slider--main .slider__control .slider__dots {
    position: absolute;
    right: 3%;
    top: 25px;
    width: 100%;
    margin: 0;
    text-align: right;
  }
  .slider--main .slider__control .slider__dots ul.slick-dots {
    position: relative;
  }
  .slider--main .slider__control .slider__dots ul.slick-dots li {
    display: inline-block;
    margin: 18px 14px;
  }
  .catalog.category .catalog__sections {
    width: 100%;
    margin: 0 0 60px;
  }
  .catalog.category .catalog__sections .catalog__sections__item {
    display: block;
    width: 100%;
    height: 355px;
  }
  .catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner {
    display: block;
    padding: 0;
    padding: inherit;
    text-align: center;
  }
  .catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__icon {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center center;
  }
  .catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner p {
    width: 100%;
    margin: 0;
    min-height: 55px;
    padding: 5px 0;
    background-color: white;
    -webkit-transition: background-color 0.3s ease 0s;
    -o-transition: background-color 0.3s ease 0s;
    transition: background-color 0.3s ease 0s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
  }
  .catalog.category .catalog__sections .catalog__sections__item .catalog__sections__item__inner:hover p {
    background-color: #258267;
    color: white;
  }
  .catalog .catalog__sections .catalog__sections__item {
    display: block;
    width: 100%;
    height: 125px;
  }
  .catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 25px 3%;
    text-align: left;
  }
  .catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__icon {
    width: 16%;
    height: 100%;
    margin-right: 60px;
  }
  .catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner .catalog__sections__item__icon svg {
    width: auto;
    height: 90%;
    margin: 0px auto;
    display: block;
  }
  .catalog .catalog__sections .catalog__sections__item .catalog__sections__item__inner p {
    font-size: 1.125em;
  }
  .poster {
    height: 242px;
  }
  .poster .poster__text1 {
    margin-bottom: 5px;
    font-size: 2em;
  }
  .poster .poster__text2 {
    font-size: 0.875em;
  }
  .poster .poster__text3 {
    margin: 30px 0;
    font-size: 2.06em;
  }
  .about {
    margin-bottom: 0;
  }
  .about .layout {
    padding-bottom: 340px;
  }
  .about .about__text {
    width: 100%;
  }
  .about .about__service {
    top: auto !important;
    width: 145px;
    font-size: 0.75em;
  }
  .about .about__service .icon {
    margin: 0 0 5px;
  }
  .about .about__service:nth-child(2),
  .about .about__service.first {
    right: 50%;
    bottom: 0px;
    margin-right: -72px;
    padding-top: 83px;
  }
  .about .about__service:nth-child(3),
  .about .about__service.second {
    right: 50%;
    bottom: 125px;
    margin-right: 5px;
    padding-top: 60px;
  }
  .about .about__service:nth-child(4),
  .about .about__service.third {
    left: 50%;
    right: auto;
    bottom: 125px;
    margin-left: 5px;
    padding-top: 67px;
  }
  .product .product__inner .product__content .product__content__top {
    display: block;
  }
  .product .product__inner .product__content .product__content__top .product__gallery {
    width: 100%;
    margin-bottom: 20px;
  }
  .product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper {
    height: 250px;
  }
  .product .product__inner .product__content .product__content__top .product__gallery .product__gallery__img__wrapper iframe {
    height: 250px;
  }
  .product .product__inner .product__content .product__content__top .product__desc {
    width: 100%;
  }
  .product .product__inner .product__content .product__content__bottom .tabs .tabs__item .tabs__item__title {
    font-size: 1em;
  }
  .contacts {
    margin: 15px auto 60px;
  }
  .contacts .layout {
    display: block;
  }
  .contacts .contacts__title {
    margin-bottom: 10px;
    font-size: 1.25em;
  }
  .contacts .contacts__title .icon {
    display: none;
  }
  .contacts .contacts__info {
    width: 100%;
  }
  .contacts .contacts__info .contacts__info__item .contacts__info__data {
    display: block;
  }
  .contacts .contacts__managers {
    width: 100%;
  }
  .services .layout {
    display: block;
  }
  .services .layout .services__menu {
    width: 100%;
    margin-bottom: 20px;
  }
  .services .layout .services__content {
    width: 100%;
  }
  .promotions__item {
    display: block;
  }
  .promotions__item .promotions__item__img {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
  }
  .promotions__item .promotions__item__content {
    width: 100%;
  }
  .about.page {
    font-size: 12px;
  }
  .about.page .layout {
    padding-bottom: 0;
  }
  .about.page .about__header {
    height: 45px;
  }
  .about.page .about__header h2 {
    height: 45px;
    line-height: 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    /*
    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    */
  }
  .about.page .about__give {
    display: block;
  }
  .about.page .about__give > div {
    width: 100%;
    margin-bottom: 30px;
  }
  .about.page .about__services {
    padding-bottom: 320px;
  }
  .about.page .about__services .about__service {
    position: absolute;
    top: auto !important;
    width: 145px;
    font-size: 12px;
  }
  .about.page .about__services .about__service .icon {
    margin: 0 0 5px;
  }
  .about.page .about__services .about__service.first {
    right: 50%;
    left: auto;
    bottom: 125px;
    margin-right: -72px;
    padding-top: 83px;
  }
  .about.page .about__services .about__service.second {
    right: 50%;
    bottom: 0px;
    margin-right: 5px;
    padding-top: 60px;
  }
  .about.page .about__services .about__service.third {
    left: 50%;
    right: auto;
    bottom: 0px;
    margin-left: 5px;
    padding-top: 67px;
  }
  .about.page .about__give {
    padding: 40px 0 0px;
  }
  .about.page .about__callback .about__callback__buttons {
    display: block;
    text-align: center;
  }
  .about.page .about__callback .about__callback__buttons .button.button--gradient {
    display: block;
    margin: 0 auto 10px;
  }
  .about.page .about__clients {
    padding-bottom: 20px;
    overflow: hidden;
  }
  .about.page .about__clients .layout {
    padding: 0;
  }
  .about.page .about__clients .clients {
    display: block;
  }
  .about.page .about__clients .clients .row,
  .about.page .about__clients .clients .cell {
    display: block;
  }
  .about.page .about__clients .clients .clients__item {
    display: block;
    width: 100%;
  }
  .popup.callback .popup__content {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
  .footer {
    height: 450px;
  }
  .footer .layout {
    display: block;
  }
  .footer .footer__right {
    padding-top: 0;
    text-align: left;
  }
  .footer .button {
    margin-top: 35px;
  }
  .footer .copyright {
    position: absolute;
    bottom: 15px;
    font-size: 0.875em;
  }
}
